const timezone = 'America/New_York'

export function getFormattedPublishDateTime(dateString: string, locale: string) {
  const date = new Date(dateString)
  const optionsDate: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: timezone }
  const optionsTime: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', timeZone: timezone, timeZoneName: 'short' }
  const dateFormattedString = date.toLocaleDateString(locale, optionsDate)
  const timeString = date.toLocaleTimeString(locale, optionsTime)

  return `${dateFormattedString} - ${timeString}`
}

export function getFormattedPublishDate(dateString: string, locale: string) {
  const date = new Date(dateString)
  const optionsDate: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: timezone }
  const dateFormattedString = date.toLocaleDateString(locale, optionsDate)

  return dateFormattedString
}
